<template>
  <div class="home-list-item">
    <div class="list-item" v-for="item in homelist" @click="btndetail(item.guideList.id)" :key="item.id">
      <div class="item-image">
        <img :src="baseUrl + item.guideList.imagepath" />
      </div>
      <div class="item-title">
        <span>{{ item.guideList.title }}</span>
      </div>

      <div class="item-list-user">
        <img :src="baseUrl + item.guideList.user.head" class="item-list-user-img" />
        <span class="item-name">{{ item.guideList.user.nickname }}</span>
        <div class="item-fabs">
          <i class="iconfont icon-heart heart"></i>
          <span>{{ item.guideList.numfabs }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeListItem2',
  props: {
    homelist: {
      type: Array
    }
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_IMG_PATH
    }
  },
  methods: {
    btndetail(cid) {
      this.$router.push('/homedetail/' + cid)
    }
  }
}
</script>

<style scoped>
.home-list-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(230, 230, 230, 0.8);
}
.list-item {
  width: 47.3%;
  margin: 4px 5px;
  background-color: #fff;
  border-radius: 8px;
}
.item-image img {
  width: 100%;
  border-radius: 8px;
}
.item-title {
  width: 100%;
  padding: 5px 0;
  margin: 0 5px;
  font-size: 15px;
}
.item-list-user img {
  width: 20px;
  border-radius: 50%;
}

.item-list-user {
  padding: 8px 10px;
  display: flex;
  align-items: center;
}

.item-name {
  font-size: 13px;
  color: rgb(120, 120, 120);
  flex: 1;
  margin-left: 6px;
}
.item-fabs {
  font-size: 14px;
  color: rgb(120, 120, 120);
  display: flex;
}
.item-fabs .icon-heart {
  margin-right: 5px;
}
.heart {
  color: rgb(120, 120, 120);
}
</style>
