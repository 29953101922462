<template>
  <div class="personal-list">
    <img class="back-image" :src="userlist.backimage" />

    <div class="user-nav">
      <i class="fas fa-angle-left" @click="btnoutother"></i>
      <!-- <span @click="outlogin" id="outlogin">退出登录</span> -->
    </div>

    <div class="user-head">
      <img :src="userlist.head" />
      <h2>{{ userlist.nickname }}</h2>
    </div>
    <div class="user-jj">
      <span>{{ userlist.jj }}</span>
    </div>

    <div class="user-gender">
      <span>{{ userlist.sex }}</span>
    </div>

    <div class="user-record">
      <div class="user-record-fabs">
        <div @click="btnseefollow">
          <span>{{ follist.length }}</span>
          <a>关注</a>
        </div>

        <div>
          <span>{{ fanslist.length }}</span>
          <a>粉丝</a>
        </div>

        <div>
          <span>1</span>
          <a>获赞</a>
        </div>
      </div>

      <div class="user-edit" id="user-edit">
        <span @click="btnsendnew">发消息</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { getUserFollow, getFans } from 'network/follow'
export default {
  name: 'Personallist',
  data() {
    return {
      follist: [],
      fanslist: []
    }
  },
  props: {
    userlist: {
      type: Object
    },
    user_id: {
      type: [Number, String]
    }
  },
  created() {
    this.getUserFollow()
    this.getFans()
  },
  updated() {},
  mounted() {
    let outlogin = document.getElementById('outlogin')

    if (this.user_id === window.sessionStorage.getItem('userId')) {
      outlogin.style.visibility = 'visible'
    } else {
      outlogin.style.visibility = 'hidden'
    }
  },
  methods: {
    ...mapMutations(['delToken']),
    outlogin() {
      window.sessionStorage.clear()
      localStorage.removeItem('userInfo')
      localStorage.removeItem('userId')
      this.delToken({ token: '' })
      this.$router.push('/login')
    },

    btnoutother() {
      this.$emit('btnoutother')
    },

    /*根据id获取关注*/
    getUserFollow() {
      getUserFollow(this.user_id).then((res) => {
        this.follist = res.data.followList
      })
    },
    /*查粉丝*/
    getFans() {
      getFans(this.user_id).then((res) => {
        this.fanslist = res.data.fansList
      })
    },
    /*查看关注*/
    btnseefollow() {},

    /*发消息*/
    btnsendnew() {
      this.$router.push('/Chat/' + this.userlist.id)
    }
  }
}
</script>

<style scoped>
.personal-list {
  width: 100%;
  background-color: rgba(80, 80, 80, 0.5);
}
.back-image {
  z-index: -1;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.user-nav {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(240, 240, 240);
}
.user-nav i {
  margin-left: 15px;
  font-size: 20px;
}
.user-nav span {
  display: none;
  font-size: 14px;
  margin-right: 20px;
  padding: 5px 10px;
  background-color: rgba(80, 80, 80, 0.5);
  border-radius: 15px;
}
.user-head {
  display: flex;
  align-items: center;
  color: #fff;
  margin: 10px 0;
}
.user-head img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 5px 15px;
}
.user-jj {
  margin: 10px 15px;
  font-size: 13px;
  color: #fff;
}
.user-gender {
  color: #fff;
  font-size: 12px;
  margin: 15px 15px;
}
.user-record {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px 0;
}
.user-record-fabs {
  display: flex;
}
.user-record-fabs div {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}
.user-record-fabs span {
  font-size: 14px;
  color: #fff;
}

.user-record-fabs a {
  color: rgb(220, 220, 220);
  font-size: 10px;
  margin: 8px 0;
}

.user-edit {
  flex: 1;
  color: #fff;
  font-size: 14px;
  margin: 3px 0 0 38%;
}
.user-edit span {
  padding: 5px 10px;
  background-color: rgba(80, 80, 80, 0.5);
  border-radius: 15px;
}
</style>
