<template>
  <div class="other-record">
    <!--导航-->
    <div class="personal-record-nav">
      <div class="record-nav" id="record-nav">
        <span v-for="(item, index) in recordcom" :key="item.id" @click="btnrecordnav(index)" id="record-nav-span"
          >{{ item }}
        </span>
        <a class="nav-line" id="nav-line"></a>
      </div>
    </div>

    <!---->
    <note-other class="note-other" id="note-other" :user_id="user_id"></note-other>

    <fabs-other class="fabs-other" id="fabs-other" :user_id="user_id"></fabs-other>
  </div>
</template>

<script>
import NoteOther from './noteandfabs/NoteOther'
import FabsOther from './noteandfabs/FabsOther'
export default {
  name: 'Otherrecord',
  props: {
    user_id: {
      type: [Number, String]
    }
  },
  components: {
    NoteOther,
    FabsOther
  },
  data() {
    return {
      recordcom: ['笔记', '赞过'],
      recordindex: 0
    }
  },
  created() {},
  methods: {
    btnrecordnav(index) {
      this.recordindex = index
      let parleft = document.getElementById('record-nav')
      let navline = document.getElementById('nav-line')
      let noteother = document.getElementById('note-other')
      let fabsother = document.getElementById('fabs-other')

      if (index === 0) {
        navline.style.left = (parleft.offsetWidth * 16) / 100 + 'px'
        fabsother.style.left = 100 + '%'
        noteother.style.left = 0 + '%'
      } else if (index === 1) {
        navline.style.left = (parleft.offsetWidth * 66) / 100 + 'px'
        fabsother.style.left = 0 + '%'
        noteother.style.left = -100 + '%'
      }
    }
  }
}
</script>

<style scoped>
.other-record {
  position: relative;
  height: 100vh;
  background-color: rgba(230, 230, 230, 0.8);
  z-index: 99;
}
.personal-record {
  height: 100vh;
  background-color: rgb(230, 230, 230);
  border-radius: 12px 12px 0 0;
}
.personal-record-nav {
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 12px 12px 0 0;
}
.record-nav {
  display: flex;
  width: 40%;
  height: 38px;
  line-height: 38px;
  background-color: #fff;
  color: rgb(120, 120, 120);
  font-size: 14px;
  text-align: center;
  position: relative;
}
.record-nav span {
  width: 33.3%;
  display: block;
  margin: 0 20px;
}
.nav-line {
  height: 1px;
  width: 28px;
  background-color: red;
  position: absolute;
  left: 16%;
  /*left: 44%;*/
  /*left: 77%;*/
  bottom: 5px;
  transition: left 0.4s;
}
.personal-record-bottom {
  width: 100%;
  height: 49px;
}
.note-other {
  position: fixed;
  top: 40%;
  left: 0;
  bottom: 0;
  transition: all 0.5s;
  overflow-y: auto;
  /*display: none;*/
  background-color: rgb(230, 230, 230);
}

/*滚动条隐藏*/
.note-other::-webkit-scrollbar {
  display: none;
}

.fabs-other {
  overflow-y: auto;
  position: fixed;
  top: 40%;
  left: 100%;
  bottom: 0;
  transition: all 0.5s;
  /*display: none;*/
  background-color: rgb(230, 230, 230);
}

/*滚动条隐藏*/
.fabs-other::-webkit-scrollbar {
  display: none;
}
</style>
