<template>
  <div class="note-other">
    <home-list-item :homelist="homelist"></home-list-item>
  </div>
</template>

<script>
import HomeListItem from '../../../home/homechild/HomeListItem'
import { getUserGuideList } from 'network/home'
export default {
  name: 'NoteOther',
  props: {
    user_id: {
      type: [Number, String]
    }
  },
  components: {
    HomeListItem
  },
  data() {
    return {
      homelist: {}
    }
  },
  created() {
    this.getUserGuideList()
  },
  methods: {
    getUserGuideList() {
      getUserGuideList(this.user_id).then((res) => {
        this.homelist = res.data
      })
    }
  }
}
</script>

<style scoped>
/*.note-other{
        width: 100%;
        height: 100vh;
        overflow-y: auto;
    }*/
</style>
