<template>
  <div>
    <home-list-item2 :homelist="homelist"></home-list-item2>
  </div>
</template>

<script>
import HomeListItem2 from '../../../home/homechild/HomeListItem2'
export default {
  name: 'FabsOther',
  props: {
    user_id: {
      type: [Number, String]
    }
  },
  components: {
    HomeListItem2
  },
  data() {
    return {
      homelist: []
    }
  },
  created() {
    this.getFabs()
  },
  methods: {
    getFabs() {}
  }
}
</script>

<style scoped></style>
