<template>
  <div class="userother">
    <otherlist @btnoutother="btnoutother" :userlist="userlist" :user_id="user_id"></otherlist>
    <otherrecord :user_id="user_id"></otherrecord>
  </div>
</template>

<script>
import Otherrecord from './Otherrecord'
import Otherlist from './Otherlist'
export default {
  name: 'UserOther',
  props: {
    userlist: {
      type: Object
    },
    user_id: {
      type: [Number, String]
    }
  },
  components: {
    Otherrecord,
    Otherlist
  },
  methods: {
    /*返回*/
    btnoutother() {
      this.$emit('btnoutother')
    }
  }
}
</script>

<style scoped>
.userother {
  width: 100%;
}
</style>
